<template>
  <div>
    <Multiselect
      v-model="selection"
      :options="options"
      :options-limit="10"
      :allow-empty="true"
      track-by="feature_id"
      label="title"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="placeholder"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="true"
      @search-change="search"
      @select="select"
      @close="close"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="selection = null"
        >
          <i
            class="close icon"
            aria-hidden="true"
          />
        </div>
      </template>
      <span slot="noResult">
        Aucun résultat.
      </span>
      <span slot="noOptions">
        Saisissez les premiers caractères ...
      </span>
    </Multiselect>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';

export default {
  name: 'SearchFeature',

  components: {
    Multiselect
  },

  props: {
    currentSelection : {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      loading: false,
      selection: null,
      text: null,
      results: [],
      placeholder: 'Rechercher un signalement ...'
    };
  },

  computed: {
    ...mapState('feature', [
      'features'
    ]),

    options() {
      return this.results.map((el) => {
        return {
          featureId: el.id,
          title: el.properties.title
        };
      });
    },
  },

  watch: {
    text: function(newValue) {
      this.loading = true;
      this.GET_PROJECT_FEATURES({
        project_slug: this.$route.params.slug,
        feature_type__slug: this.$route.params.slug_type_signal,
        search: newValue,
        limit: '10'
      })
        .then(() => {
          if (newValue) { // filter out current feature
            this.results = this.features.filter((el) => el.id !== this.$route.params.slug_signal);
          } else {
            this.results.splice(0);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  },

  created() {
    this.RESET_CANCELLABLE_SEARCH_REQUEST();
  },

  mounted() {
    if (this.currentSelection && this.currentSelection.feature_to) {
      this.placeholder = this.currentSelection.feature_to.title;
    }
  },

  methods: {
    ...mapMutations(['RESET_CANCELLABLE_SEARCH_REQUEST']),
    ...mapActions('feature', [
      'GET_PROJECT_FEATURES'
    ]),
    search(text) {
      this.text = text;
    },
    select(e) {
      this.$emit('select', e.featureId);
    },
    close() { // close calls as well selectFeatureTo, in case user didn't select a value
      this.$emit('close', this.selection && this.selection.featureId ?
        this.selection.featureId : this.selection);
    }
  }
};
</script>

<style>
.multiselect input {
  line-height: 1em !important;
  padding: 0 !important;
}

.multiselect__placeholder {
  margin: 0;
  padding: 0;
}

.multiselect__input {
  min-height: auto !important;
  line-height: 1em !important;
}
</style>
